import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import LeftImageSection from "components/items/image-left-section"
import RightImageSection from "components/items/image-right-section"

import ImagePengelola from "resources/img/sayur/pengelolaan.png"
import ImageJualan from "resources/img/sayur/penjualan.png"
import ImageJangkauan from "resources/img/sayur/jangkauan.png"

const ContentSection = () => {
  return (
    <div className="main-content-section">
      <StaticImage
        className="shape-right-content-partner"
        placeholder="none"
        src={"../../../resources/img/shape/round-shape-green-2.png"}
        alt="shape-right"
      />
      <LeftImageSection
        img={ImagePengelola}
        title="Pengelolaan setiap pesanan masuk yang mudah."
        desc="Anda bisa mengelola pesanan masuk dan berbagai informasi
        penting lainnya melalui aplikasi wispay."
      />
      <RightImageSection
        img={ImageJualan}
        title="Proses berjualan yang mudah dan cepat."
        desc="Kini kamu bisa berjualan dan mendistribusikan sayur jualanmu
        dengan lebih cepat."
      />
      <LeftImageSection
        img={ImageJangkauan}
        title="Jangkauan customer yang lebih luas."
        desc="Kini kamu bisa menerima pesanan sayur hanya dengan klik dan
        klik di aplikasi wispay."
      />
      <StaticImage
        className="shape-left-content-partner"
        placeholder="none"
        src={"../../../resources/img/shape/round-shape-green-1.png"}
        alt="shape left"
      />
    </div>
  )
}

export default ContentSection
