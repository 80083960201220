import React, { memo } from "react"
import { Typography } from "antd"
import { Link } from "gatsby"

const Gradient = ({ variant, title, href, style, target }) => {
  return (
    <Link
      to={href}
      style={style}
      className={`btn-gradient-rounded ${variant}`}
      target={target}
    >
      <Typography.Text>{title}</Typography.Text>
    </Link>
  )
}

export default memo(Gradient)
