import React from "react"
import { Col, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"

const TitleSection = () => {
  return (
    <div id="keunggulan" className="base-container-title">
      <StaticImage
        className="shape-left-title-partner"
        placeholder="none"
        src={"../../../resources/img/shape/round-shape-green-1.png"}
        alt="shape left"
      />
      <Col span={20}>
        <div className="container-title">
          <Typography.Text className="text-title-35 ">
            Beragam Keunggulan menjadi Mitra Jual Pulsa
          </Typography.Text>
          <div className="divider-title-green" />
        </div>
      </Col>
    </div>
  )
}

export default TitleSection
