import React from "react"
import { Col, Typography } from "antd"

const RightImageSection = ({ img, title, desc }) => {
  return (
    <div className="base-container-image-section">
      <div className="container-image-section">
        <Col span={20}>
          <div className="container-right-section">
            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
              <div className="area-image-section">
                <img
                  className="image-section"
                  src={img}
                  alt="illustration section"
                />
              </div>
            </Col>
            <div>
              <Col xl={20} lg={24} md={24} sm={24} xs={24}>
                <div className="area-text-section">
                  <Typography.Text className="text-title">
                    {title}
                  </Typography.Text>
                  <Typography.Text className="text-desc">
                    {desc}
                  </Typography.Text>
                </div>
              </Col>
            </div>
          </div>
        </Col>
      </div>
    </div>
  )
}

export default RightImageSection
