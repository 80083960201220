import React from "react"
import { Col, Typography } from "antd"

import BackgroundBergabung from "resources/img/sayur/bergabung-dengan-kami.png"

const BergabungSection = () => {
  return (
    <div id="produk">
      <div className="base-container-gabung-sayur">
        <Col xl={20} lg={20} md={20} sm={24} xs={24}>
          <div className="container-sayur">
            <img
              className="shape-bg"
              src={BackgroundBergabung}
              alt="bg bergabung"
            />
            <div className="img-container-download" />
            <div className="linear-container-download" />
            <div className="container-area-image-section">
              <div className="center-middle">
                <Typography.Text className="title-download">
                  Bergabung bersama kami.
                </Typography.Text>
                <Typography.Text className="desc-download">
                  Ayo, segera bergabung menjadi Mitra kami dan nikmati berbagai
                  keunggulan yang kami tawarkan.
                </Typography.Text>
                <div
                  className="btn-gradient-rounded green-linear"
                  style={{ cursor: "default", margin: 0 }}
                >
                  <Typography.Text>Coming Soon</Typography.Text>
                </div>
              </div>
              {/* <Row className="area-image-download" gutter={16}>
                  <Col justify="center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.wispay"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={Google}
                        alt="button play store"
                        className="img-download"
                      />
                    </a>
                  </Col>
                  <Col justify="center">
                    <img
                      src={Appstore}
                      className="img-download"
                      alt="button app store"
                    />
                  </Col>
                </Row> */}
            </div>
          </div>
        </Col>
      </div>
    </div>
  )
}

export default BergabungSection
