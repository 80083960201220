import * as React from "react"
import "resources/style/style.less"
import TopSectionBG from "components/section/sayur/top.sc"
import TitleSection from "components/section/sayur/title.sc"
import BergabungSection from "components/section/sayur/bergabung.sc"
import ContentSection from "components/section/sayur/content.sc"
import Seo from "../components/seo"
import Layout from "components/layout"

const IndexPage = () => {
  return (
    <>
      <Seo title={"Wispay sayur"} />
      <Layout headerVariant="normal" headerBackground="green" page="sayur">
        <TopSectionBG />
        <TitleSection />
        <ContentSection />
        <BergabungSection />
      </Layout>
    </>
  )
}

export default IndexPage
