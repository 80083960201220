import React from "react"
import ButtonGradient from "components/button/Gradient"
import { Col, Row, Typography } from "antd"
import { StaticImage } from "gatsby-plugin-image"

const TopSectionBG = () => {
  return (
    <div>
      <div className="container-background-top-area">
        <StaticImage
          className="top-background-sayur"
          src="../../../resources/img/bg-banner-sayur.svg"
          placeholder="none"
          alt="top background banner sayur"
        />
      </div>

      <div className="top-area">
        <Col span={20}>
          <Row justify="center" align="middle">
            <Col xl={11} lg={11} md={18} sm={24} xs={24}>
              <Col span={24}>
                <div className="container-text-mobile-center">
                  <Typography.Text className="text-big-banner">
                    Jualan{" "}
                    <Typography.Text className="text-big-banner">
                      Sayur dan Bumbu dapur
                    </Typography.Text>{" "}
                    kini lebih mudah & menguntungkan
                  </Typography.Text>
                </div>
              </Col>

              <Col span={24} xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="container-text-mobile-center">
                  <Typography.Text className="text-normal-banner">
                    Segera bergabung bersama kami
                  </Typography.Text>
                </div>
              </Col>
              <Col md={24} sm={24} xs={24}>
                <Row className="button-top">
                  <ButtonGradient
                    style={{ marginTop: 35 }}
                    variant="white-linear"
                    title="Pelajari"
                    href="#keunggulan"
                  />
                </Row>
              </Col>
            </Col>
            <div className="img-area">
              <StaticImage
                alt="Outlet app"
                placeholder="none"
                className="img-background-mockup-sayur"
                src="../../../resources/img/phone-banner-sayur.png"
              />
            </div>
            <Col span={13}></Col>
          </Row>
        </Col>
      </div>
    </div>
  )
}

export default TopSectionBG
